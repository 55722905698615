<template>
  <Spinner v-show="spinner_waitData" class="style-spinner" />
  <!-- <h1>hi</h1> -->

  <div class="warpper-content">
    <img src="../assets/logo-ttb.svg" alt="" class="logo" />
    <div class="container d-grid justify-content-center">
      <!-- 
    ===== firstname =====
    -->
      <div
        class="form__group"
        :class="{ invalid: validate_firstname === 'invalid' }"
      >
        <input
          type="text"
          class="form__field"
          :class="{ inputFocusStyle: validate_firstname !== 'invalid' }"
          placeholder="Firstname"
          name="firstname"
          id="firstname"
          required
          :disabled="isDisable"
          v-model="firstname"
          @blur="validateFirstname"
          @input="validateFirstname"
          maxlength="50"
        />
        <label for="firstname" class="form__label">ชื่อ</label>

        <!-- Text Alret Error -->
        <p v-if="validate_firstname === 'invalid'" class="d-flex">
          Please enter first name
        </p>
      </div>
      <!-- 
    ===== lastname =====
    -->
      <div
        class="form__group"
        :class="{ invalid: validate_lastname === 'invalid' }"
      >
        <input
          type="text"
          class="form__field"
          :class="{ inputFocusStyle: validate_lastname !== 'invalid' }"
          placeholder="Lastname"
          name="lastname"
          id="lastname"
          required
          :disabled="isDisable"
          v-model="lastname"
          @blur="validateLastname"
          @input="validateLastname"
          maxlength="50"
        />
        <label for="lastname" class="form__label">นามสกุล</label>

        <!-- Text Alret Error -->
        <p v-if="validate_lastname === 'invalid'" class="d-flex">
          Please enter last name
        </p>
      </div>
      <!-- 
    ===== Emai =====
    -->
      <div
        class="form__group"
        :class="{ invalid: validate_email === 'invalid' }"
      >
        <input
          type="text"
          class="form__field"
          :class="{ inputFocusStyle: validate_email !== 'invalid' }"
          placeholder="Email"
          name="email"
          id="email"
          required
          :disabled="isDisable"
          v-model="email"
          @blur="validateEmail"
          @input="validateEmail"
          maxlength="50"
        />
        <label for="email" class="form__label">อีเมล</label>

        <!-- Text Alret Error -->
        <p v-if="validate_email === 'invalid'" class="d-flex">
          Please enter a valid email address
        </p>
      </div>
      <!-- 
    ===== Number =====
    -->
      <div
        class="form__group"
        :class="{ invalid: validate_number === 'invalid' }"
      >
        <input
          type="tel"
          class="form__field"
          :class="{ inputFocusStyle: validate_number !== 'invalid' }"
          placeholder="Number"
          name="number"
          id="number"
          required
          :disabled="isDisable"
          v-model="phone"
          v-on:keydown="checkValidatePhneNumberOnKeyDown"
          @input="validateNumber"
          @blur="validateNumber"
          maxlength="10"
        />
        <label for="number" class="form__label">โทรศัพท์มือถือ</label>

        <!-- Text Alret Error -->
        <p v-if="validate_number === 'invalid'" class="d-flex">
          Please input 10 digits number (First digits must be 0)
        </p>
      </div>
    </div>

    <!--  </form>-->
    <div class="mainSelect">
      <div class="wrapMain__Select">
        <div class="wrap__select">
          <!--Select with pure css-->
          <div class="content__select">
            <select
              :class="active ? 'select-active' : 'select-noActive'"
              @change="changeSelectService()"
              v-model="service"
            >
              <option
                v-for="(option, index) in options"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <label class="select-label">ต้องการให้เราช่วยเรื่องอะไร</label>
            <img
              src="../assets/ep_arrow-down-bold.svg"
              alt=""
              class="seclect__arrow"
              
            />
          </div>
          <!--Select with pure css-->
        </div>
      </div>
    </div>
    <BaseButton
      Title="เริ่มการสนทนา"
      class="end-btn"
      :Enabled="disabledSubmit"
      @click="SubmitPreScreen()"
      name="subject"
    />
  </div>
</template>

<script>
import axios from 'axios'
import config from '../config'
import BaseButton from '../components/BaseButton.vue'
import BaseSelect from '../components/BaseSelect.vue'
import Spinner from '../views/Spinner.vue'
export default {
  components: { Spinner },
  name: 'BaseForm',
  component: {
    BaseButton,
    BaseSelect,
    Spinner,
  },
  props: {
    title: String,
    ErrorNessage: String,
    Type: String,
    PlaceHolder: String,
    Error: Boolean,
    isDisable: Boolean,
  },

  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      number: '',
      phone: '',
      spinner_waitData: false,
      disabledSubmit: true,
      validate_firstname: 'pending',
      validate_lastname: 'pending',
      validate_email: 'pending',
      validate_number: 'pending',
      active: false,
      options: [],
      service: '',
      checkValidate: false,
    }
  },
  updated() {
    if (this.validate_email === 'invalid') {
      this.disabledSubmit = true
    }
  },
  mounted() {
    this.getSubject()
  },
  watch: {
    firstname(newValue) {
      this.checkSpecialChars(newValue, 'firstname')
    },
    lastname(newValue) {
      this.checkSpecialChars(newValue, 'lastname')
    },
    phone(newValue) {
      this.checkSpecialChars(newValue, 'phone')
    },
  },
  methods: {
    checkSpecialChars(value, type) {
      if (type == 'phone') {
        let spcr = /^[0-9]+$/
        if (!spcr.test(value)) {
          this[type] = value.replace(
            // eslint-disable-next-line no-useless-escape
            /[A-Za-z `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¥÷∑€£₩]/g,
            ''
          )
        }
      } else {
        let spcr = /^[A-Za-zก-ฮะ-์\s]+$/
        if (!spcr.test(value)) {
          this[type] = value.replace(
            // eslint-disable-next-line no-useless-escape
            /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¥÷∑€£₩]/g,
            ''
          )
        }
      }
    },
    checkValidateNameOnKeyDown(e) {
      if (!/^[A-Za-zก-ฮะ-์\s]+$/.test(e.key)) {
        e.preventDefault()
      }
    },
    checkValidatePhneNumberOnKeyDown(e) {
      if (
        !/^[0-9]+$/.test(e.key) &&
        e.key !== 'Backspace' &&
        e.key !== 'ArrowLeft' &&
        e.key !== 'ArrowRight' &&
        this.validate_number == 'invalid'
      ) {
        e.preventDefault()
        this.validate_number = 'invalid'
        this.disabledSubmit = true
      }
    },
    validateFirstname() {
      let spcr = /^[A-Za-zก-ฮะ-์\s]+$/
      if (this.firstname.trim() === '' || !spcr.test(this.firstname)) {
        this.validate_firstname = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_firstname = 'valid'
      }
      this.Validate()
    },
    validateLastname() {
      let spcr = /^[A-Za-zก-ฮะ-์\s]+$/
      if (this.lastname.trim() === '' || !spcr.test(this.lastname)) {
        this.validate_lastname = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_lastname = 'valid'
      }
      this.Validate()
    },
    validateEmail() {
      if (
        this.email.trim() === '' ||
        !this.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        this.validate_email = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_email = 'valid'
      }
      this.Validate()
    },
    validateNumber() {
      let first = this.phone.slice(0, 1)
      if (this.phone.trim() === '' || this.phone.length < 10 || first !== '0') {
        this.validate_number = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_number = 'valid'
      }
      this.Validate()
    },
    changeSelectService() {
      this.Validate()
      this.active = true
    },
    Validate() {
      if (
        this.firstname === '' &&
        this.lastname === '' &&
        this.email === '' &&
        (this.phone === '' || this.phone.length < 10) &&
        this.service === ''
      ) {
        this.checkValidate = false
        this.disabledSubmit = true
      }
      if (
        this.firstname != '' &&
        this.lastname != '' &&
        this.email != '' &&
        this.phone != '' &&
        this.phone.length === 10 &&
        this.validate_number === 'valid' &&
        this.service != ''
      ) {
        this.checkValidate = false
        this.disabledSubmit = false
      }
    },
    getSubject() {
      this.spinner_waitData = true
      axios
        .get(`${config.baseUrl}/webchat/subject`)
        .then((res) => {
          this.spinner_waitData = false
          if (res.status === 'success' || res.status === 200) {
            this.options = res.data.subject
          }
        })
        .catch((error) => {
          this.spinner_waitData = false
          this.errorPage(error)
        })
    },
    SubmitPreScreen() {
      this.Validate()
      if (!this.disabledSubmit) {
        let $this = this
        let data = {
          firstName: this.firstname,
          lastName: this.lastname,
          email: this.email,
          phone: this.phone.split('-').join(''),
          timestamp: new Date().getTime(),
          websession_id: `private-${this.$store.getters.getChannelId}`,
          subject: this.service
        }
        this.spinner_waitData = true
        axios
          .post(`${config.baseUrl}/webchat/prescreen`, data)
          .then((res) => {
            if (res.status == 200) {
              this.$store.state.greetingMessages = res.data.greeting
              this.$store.state.sessionId = res.data.sessionId
              $this.$router.push({
                name: 'Chat',
                params: {
                  session: this.$store.getters.getChannelId,
                },
              })
              this.spinner_waitData = false
            }
          })
          .catch((error) => {
            this.errorPage(error)
          })
      } else {
        console.log('error: validate form')
      }
    },
    errorPage(error) {
      console.log(error)
      this.$router.push('/error-page')
    },
  },
}
</script>

<style scoped>
.logo {
  margin: 2rem 0;
}
* {
  box-sizing: border-box;
}
.warpper-content {
  height: max(600px, 100vh);
  position: relative;
}
.form__group {
  position: relative;
  padding: 8px 12px;
  /* margin-top: 10px; */
  border: 1px solid #dfe6ec;
  border-radius: 0.75rem;
  height: 3.75rem;
  max-width: 19.375rem;
  width: 19.365rem;
  margin: auto;
  margin-bottom: 0.75rem;
}
.form__field {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1rem;
  color: #002d63;
  padding: 15px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1rem;
  cursor: text;
  top: 20px;
  color: #9eafc2;
}
.form__label {
  position: absolute;
  top: 8.5px;
  display: block;
  transition: 0.2s;
  font-size: 0.75rem;
  color: #002d63;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.75rem;
  color: #002d63;
  top: 8.5px;
}
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
.style-spinner {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  left: 0%;
}
.inputFocusStyle:focus {
  border: #2e4c88 1px solid;
  margin-top: -8px;
  height: 3.75rem;
  width: 19.365rem;
  margin-left: -13px;
  border-radius: 0.75rem;
  padding-left: 12px;
  padding-top: 24.5px;
}
/* 
==========================
    ส่วนของ Error
==========================
*/
.invalid {
  border-color: red !important;
  margin-bottom: 2rem;
}
.invalid p {
  margin-left: -0.7rem;
  color: red;
  font-size: 0.75rem;
}

/* 
==========================
    ส่วนของ Selects
==========================
*/
.mainSelect {
  width: 19.375rem;
  position: relative;
  margin: auto;
}
.wrapMain__Select {
  border: 1px solid #dfe6ec;
  border-radius: 12px;
  width: 100%;
  height: 3.75rem;
  position: relative;
}
.wrap__select {
  width: 100%;
}

/* select starting stylings ------------------------------*/
.content__select {
  position: relative;
}
select {
  border: unset;
  background-color: transparent;
  padding: 15px 12px 0px 12px;
}

.select-noActive {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  height: 3.75rem;
  border: unset;
  border-radius: 12px;
  font-size: 16px;
  border-radius: 0;
  color: #002d63;
}
.select-active {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  height: 3.75rem;
  border-radius: 12px;
  font-size: 16px;
  border-radius: 0;
  color: #002d63;
}

/* Remove focus */
.select-noActive:focus {
  outline: none;
  border: 1px solid #2e4c88;
  border-radius: 12px;
}
.select-active :focus {
  outline: none;
  border: unset;
}
.select-active:focus-visible {
  border: unset;
  outline: none;
}

/* Use custom arrow */
.content__select .select-active {
  appearance: none;
}
.content__select .select-noActive {
  appearance: none;
}

.seclect__arrow {
  position: absolute;
  top: 18px;
  right: 10px;
  cursor: pointer;
  z-index: -1;
}

/* LABEL ======================================= */
.select-label {
  color: #9eafc2;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  height: 100%;
  transition: 0.2s ease all;
  padding: 15px 12px;
}

/* active state */
.select-active:focus ~ .select-label,
.select-active:valid ~ .select-label {
  color: #002d63;
  padding: 8px 12px;
  transition: 0.2s ease all;
  font-size: 12px;
}
/* active state */

.spinner-center {
  height: 100vh;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.highlightRoundBox {
  position: relative;
  width: 30px;
  height: 30px;
}

.loading {
  display: block;
  position: relative;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  border: 5px solid #0050f0;
  border-top-color: #e4edfe;
  animation: spin 2s infinite linear;
}

.loading .edge {
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  background: #0050f0;
}
.loading .edge.edge-left {
  top: -1.5px;
  left: -1px;
}
.loading .edge.edge-right {
  top: -1.5px;
  right: -1px;
}

.end-btn {
  left: 50%;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}
</style>
